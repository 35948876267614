import React, { type FunctionComponent } from 'react';
import { useForm } from 'react-hook-form';
import { StyledButton, TextButton } from '../../buttons';
import { TextInput } from '../../inputs';
import { InputGroup } from '../../inputs/input-group/input-group.component';
import { CancelIcon, ChevronRightIcon } from '../../svg/icons.component';

type FormInputs = {
	location: string;
};

export type FindNearbyLocationsProps = {
	onSuccessSubmit: (input: string) => void;
	userZipcode?: string;
	showUseCurrentLocation?: boolean;
	className?: string;
};

export const FindNearbyLocations: FunctionComponent<FindNearbyLocationsProps> = ({
	onSuccessSubmit,
	userZipcode,
	showUseCurrentLocation = true,
	className = ''
}) => {
	const {
		register,
		handleSubmit,
		formState: { errors },
		reset
	} = useForm<FormInputs>();

	const onSubmit = (formData: FormInputs) => {
		onSuccessSubmit(formData.location);
		reset();
	};

	const useCurrentLocationClicked = () => {
		if (userZipcode) {
			onSuccessSubmit(userZipcode);
			reset();
		}
	};

	return (
		<div className={className} data-testid="find-nearby-locations">
			<form
				onSubmit={handleSubmit(onSubmit)}
				className="flex flex-column"
				aria-label="search showrooms by location"
				data-testid="find-nearby-locations-form">
				<InputGroup>
					<TextInput
						placeholder="City, State, or Zip Code"
						ariaLabel="city, state or zip code entry"
						testId="location-search-input"
						maxLength={50}
						{...register('location')}
					/>
					<StyledButton buttonType="submit" testId="submit-location">
						<ChevronRightIcon className="f3 nt2 nr4 nb2 nl3" />
					</StyledButton>
				</InputGroup>

				{/* Input Group doesn't support form invalid state */}
				{errors.location && (
					<div className={`flex flex-row items-center mt3 f6 theme-error`} data-testid="input-error-message">
						<CancelIcon className="f4" />
						{errors.location?.message}
					</div>
				)}
			</form>

			{showUseCurrentLocation && (
				<p>
					Or{' '}
					<TextButton onClick={useCurrentLocationClicked} testId="use-current-location">
						Use Current Location
					</TextButton>
				</p>
			)}
		</div>
	);
};
